import React from 'react';

import './style.css';

export default () => (
  <a
    href="http://vendoka.com"
    className="github-corner"
    aria-label="View source on Github"
  >
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="80"
      height="80"
      viewBox="0 0 300 300"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,331.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M975 3250 c-6 -10 -5 -11 14 -14 5 0 13 -4 17 -8 17 -17 23 -7 7 12
-19 22 -29 25 -38 10z"/>
<path d="M659 3237 c7 -7 18 -7 33 0 20 9 20 10 -10 10 -22 0 -29 -4 -23 -10z"/>
<path d="M767 3225 c-17 -12 -12 -14 52 -21 50 -5 71 -11 71 -20 0 -8 7 -14
17 -14 30 0 -14 37 -65 54 -54 19 -52 19 -75 1z"/>
<path d="M440 3201 c-11 -21 -4 -22 140 -36 30 -3 69 -8 85 -11 l30 -5 -28 15
c-27 15 -64 24 -117 30 -14 1 -35 8 -47 14 -31 17 -51 15 -63 -7z"/>
<path d="M946 3175 c10 -8 31 -15 47 -15 16 0 27 -5 27 -14 0 -26 -28 -38 -80
-34 -27 1 -50 0 -50 -4 0 -12 129 -9 152 3 12 6 61 12 109 12 56 1 85 5 83 11
-3 7 -39 11 -88 12 -58 0 -94 6 -120 18 -21 10 -51 19 -68 22 -29 3 -30 2 -12
-11z"/>
<path d="M710 3151 c-12 -9 -12 -11 3 -17 9 -3 26 -4 37 -1 10 3 21 0 23 -6 2
-6 14 -7 33 -2 l29 8 -35 8 c-19 4 -44 10 -55 13 -11 4 -27 2 -35 -3z"/>
<path d="M349 3129 c-11 -21 -10 -22 22 -17 46 8 123 -29 127 -60 2 -13 -2
-22 -10 -22 -16 1 -136 26 -164 35 -15 5 -23 2 -27 -9 -5 -12 0 -16 16 -16 48
0 137 -49 137 -76 0 -7 -119 2 -128 11 -7 7 -71 35 -80 35 -3 0 4 -9 16 -21
27 -25 20 -39 -19 -39 -18 0 -28 -4 -24 -10 7 -11 63 -9 81 3 12 7 111 3 134
-6 8 -4 28 -7 45 -9 20 -2 34 -12 44 -30 20 -35 8 -45 -32 -29 -18 7 -67 15
-110 18 -69 5 -77 4 -71 -11 4 -10 18 -16 38 -17 17 0 67 -8 111 -17 87 -18
105 -39 48 -55 -26 -8 -31 -14 -31 -38 1 -16 -1 -32 -4 -35 -5 -5 -162 -8
-183 -4 -5 1 -21 2 -35 1 -21 -1 -23 -3 -8 -11 21 -13 84 -21 166 -23 60 -2
63 -3 60 -26 -3 -23 -4 -24 -117 -22 -63 1 -117 4 -121 6 -3 2 -25 6 -48 8
-44 5 -59 -9 -20 -17 45 -10 204 -26 256 -26 49 0 52 -1 52 -25 0 -25 -2 -25
-52 -20 -29 3 -89 7 -133 10 -44 3 -96 8 -116 11 -25 4 -39 1 -49 -10 -7 -9
-11 -16 -9 -17 27 -5 150 -12 302 -17 54 -2 57 -3 57 -27 0 -14 -6 -25 -12
-26 -29 -2 -108 -2 -183 0 -112 3 -189 2 -184 -3 16 -16 264 -24 341 -12 l38
6 0 -54 c0 -51 -2 -55 -30 -65 -39 -13 -38 -19 1 -13 28 4 30 2 27 -19 -2 -18
-11 -25 -38 -30 -45 -8 -123 -27 -180 -44 -25 -7 -61 -16 -81 -18 -29 -4 -97
-40 -119 -64 -11 -11 66 10 90 25 14 9 45 19 70 23 70 11 122 26 165 49 21 11
52 20 67 20 23 0 28 -4 28 -23 0 -19 -8 -26 -37 -34 l-38 -10 38 -2 c30 -1 37
-5 37 -21 0 -14 -11 -22 -42 -32 -24 -6 -56 -19 -73 -28 -16 -9 -52 -22 -80
-30 -27 -7 -67 -24 -87 -37 -21 -13 -45 -23 -55 -23 -12 0 -14 -3 -6 -11 7 -7
27 -1 67 20 31 17 62 31 69 31 7 0 30 9 52 20 22 11 45 20 52 20 8 0 24 6 36
14 32 21 72 24 72 6 0 -9 -42 -36 -105 -67 -58 -29 -110 -57 -116 -61 -20 -18
-107 -52 -129 -52 -14 0 -25 -7 -28 -20 -3 -11 -1 -20 4 -20 14 0 99 41 105
51 3 5 14 9 24 9 11 0 27 9 37 19 10 11 41 28 70 39 28 11 64 29 79 41 24 19
64 29 64 15 0 -3 -5 -14 -10 -25 -7 -13 -7 -19 0 -19 19 0 10 -29 -16 -47 -14
-10 -37 -28 -52 -41 -24 -21 -23 -21 13 2 43 28 65 33 65 14 0 -18 -12 -33
-35 -46 -19 -11 -18 -11 8 -12 27 0 36 -13 17 -25 -5 -3 -6 -13 -3 -21 3 -9 0
-20 -8 -25 -11 -7 -11 -9 4 -9 27 0 21 -36 -13 -72 -36 -37 -40 -51 -5 -18 23
21 25 22 31 5 7 -18 -22 -85 -38 -85 -4 0 -8 -5 -8 -12 0 -6 -7 -20 -17 -30
-9 -10 -12 -18 -7 -18 5 0 20 17 34 38 l25 37 3 -32 c2 -17 -4 -45 -12 -62
-20 -38 -21 -63 -2 -38 13 16 15 12 18 -40 1 -32 1 -83 -2 -113 -3 -30 -1 -62
4 -71 6 -11 3 -39 -8 -80 -10 -35 -21 -80 -25 -99 -5 -19 -12 -44 -17 -55 -7
-18 -6 -18 9 -7 9 8 19 35 23 60 l8 47 7 -35 c8 -37 -3 -151 -21 -210 -12 -42
-12 -40 -1 -40 5 0 12 8 14 18 3 9 5 -68 6 -173 1 -104 4 -200 7 -211 8 -31
49 -46 139 -52 44 -2 82 -8 85 -13 12 -18 130 114 130 145 0 12 -4 17 -8 11
-4 -5 -14 -15 -22 -22 -14 -10 -16 2 -15 105 0 64 2 106 3 92 2 -13 13 -35 23
-49 12 -15 19 -40 19 -68 0 -29 4 -42 11 -38 5 4 18 0 29 -10 24 -21 113 -55
148 -55 15 0 45 -6 67 -14 22 -7 63 -16 90 -19 28 -3 41 -3 30 0 -11 3 -23 10
-26 15 -3 5 -26 11 -50 13 -70 6 -188 35 -228 58 -45 26 -61 45 -61 74 0 13
-9 43 -19 66 -24 52 -35 294 -13 289 6 -1 12 7 12 18 0 11 4 20 9 20 5 0 11
10 13 22 4 30 25 34 31 6 3 -16 4 -13 4 9 -1 30 19 67 32 59 4 -3 13 9 20 25
19 43 29 36 34 -23 3 -51 3 -50 5 13 2 52 6 70 22 84 19 17 20 17 20 0 0 -20
10 -15 13 7 2 7 7 15 12 18 6 3 11 -18 13 -50 4 -68 22 -93 22 -30 0 38 2 42
14 30 7 -8 26 -48 42 -89 34 -90 120 -202 165 -216 15 -5 79 -77 79 -90 0 -3
-12 2 -27 12 -18 12 -25 13 -22 4 3 -7 29 -29 59 -49 29 -21 56 -43 58 -49 5
-18 -28 -15 -59 5 -61 40 -86 57 -114 81 l-30 26 28 -32 c16 -17 30 -35 30
-40 1 -4 7 -8 14 -8 6 0 19 -7 30 -16 10 -9 43 -26 73 -38 51 -21 80 -44 80
-63 0 -4 -6 -5 -14 -2 -8 3 -17 1 -20 -5 -4 -6 8 -13 28 -17 42 -7 67 -31 47
-43 -9 -6 -5 -10 13 -15 46 -11 27 -26 -34 -26 -47 0 -60 -3 -60 -15 0 -11
-12 -15 -47 -16 -48 -1 -48 -1 -13 -9 26 -7 41 -5 60 7 30 20 141 24 147 5 3
-7 -10 -19 -29 -28 l-33 -15 48 6 c26 4 47 3 47 -2 0 -12 -99 -56 -145 -64
l-40 -8 45 3 c25 2 71 13 102 25 38 15 63 19 73 13 12 -6 4 -14 -39 -35 -30
-15 -62 -27 -70 -27 -8 0 -18 -4 -21 -10 -3 -5 -15 -10 -26 -10 -12 0 -23 -10
-29 -25 -5 -14 -15 -25 -21 -25 -7 0 -9 -3 -6 -7 4 -3 15 1 24 9 38 34 217
108 257 108 16 -1 13 -5 -15 -20 -20 -11 -39 -26 -42 -34 -5 -11 3 -10 31 6
37 21 87 36 152 44 32 4 30 2 -17 -18 -72 -31 -67 -41 7 -15 43 14 59 24 56
34 -3 8 -2 12 3 9 12 -8 73 13 91 31 13 13 12 14 -7 8 -17 -5 -23 -2 -23 9 0
9 6 16 14 16 7 0 20 8 27 17 13 15 11 16 -18 10 -39 -8 -45 9 -8 23 14 5 25
12 25 15 0 2 -12 0 -26 -5 -35 -13 -50 -3 -29 20 16 18 15 19 -17 22 l-33 3
42 24 c24 14 41 27 38 29 -2 2 -20 -5 -40 -17 -46 -27 -58 -27 -53 3 4 19 1
22 -14 18 -23 -6 -24 16 0 35 16 13 15 14 -5 8 -38 -10 -51 7 -18 22 l30 14
-37 -5 c-42 -5 -49 5 -18 27 19 13 18 14 -17 13 -32 -1 -38 2 -38 19 0 11 -8
21 -17 23 -23 4 -32 32 -10 33 9 1 4 4 -13 8 -17 3 -31 14 -33 24 -3 14 4 17
40 18 l43 1 -59 13 c-40 9 -65 20 -77 36 -20 27 -18 35 8 24 10 -5 23 -9 28
-10 6 -1 13 -3 18 -5 17 -6 67 -4 67 3 0 4 -12 7 -27 7 -63 0 -154 50 -176 97
-9 19 -8 26 4 34 8 5 9 9 3 9 -20 0 -100 70 -132 114 -38 53 -40 61 -9 45 12
-6 -14 23 -58 66 -75 74 -90 112 -26 66 16 -12 32 -21 37 -21 4 0 22 -11 40
-25 45 -35 60 -31 21 5 -61 56 -93 79 -152 107 -58 28 -82 53 -50 53 9 0 13 5
10 10 -3 6 -14 10 -24 10 -18 0 -51 29 -51 45 0 4 25 5 55 3 30 -3 52 -1 49 4
-3 4 -31 8 -63 8 -40 0 -63 5 -71 15 -18 22 -9 27 62 35 68 8 45 14 -39 10
-29 -1 -53 2 -53 7 0 20 26 33 69 34 l46 2 -39 6 c-37 6 -38 7 -21 21 13 11
40 15 92 14 72 -1 84 15 13 17 -19 0 -46 4 -60 9 -25 7 -25 7 10 23 21 10 28
16 17 17 -30 0 -10 17 30 25 36 8 37 8 13 15 -21 7 -18 8 18 9 40 1 56 -11 30
-22 -7 -3 -3 -6 10 -6 15 -1 22 5 22 18 0 12 9 22 23 25 12 2 -6 2 -40 -1 -52
-4 -63 -2 -63 10 0 20 21 26 94 26 32 0 61 3 64 6 3 3 -23 5 -58 5 -54 0 -61
2 -50 15 8 9 30 15 56 15 24 0 44 5 44 10 0 6 -11 10 -24 10 -14 0 -27 4 -30
9 -8 13 30 20 72 14 l37 -6 -27 22 c-14 11 -31 21 -38 21 -6 0 -9 4 -6 9 5 8
57 2 124 -14 35 -8 28 -35 -10 -36 -18 0 -27 -3 -20 -6 20 -8 14 -33 -9 -33
-17 0 -19 -3 -10 -14 9 -11 6 -15 -15 -20 -20 -5 -23 -10 -15 -20 13 -16 4
-26 -22 -26 -15 0 -17 -3 -8 -13 13 -16 5 -27 -20 -27 -12 0 -16 -6 -12 -20 5
-17 0 -20 -32 -20 l-38 0 28 -26 c15 -14 24 -29 19 -32 -20 -15 -8 -45 26 -63
19 -11 40 -26 47 -35 14 -20 7 -38 -10 -27 -7 4 -6 0 2 -10 7 -9 19 -17 25
-17 7 0 23 -12 37 -26 l24 -26 -50 7 c-27 4 -63 15 -80 25 -16 10 -46 21 -65
24 -27 5 -17 -2 40 -31 41 -20 78 -41 81 -46 3 -5 9 -2 12 7 11 28 72 -10 132
-82 l29 -35 -24 6 c-21 5 -18 0 15 -26 62 -49 87 -76 80 -87 -3 -5 -13 -7 -23
-3 -9 3 6 -11 33 -31 57 -42 70 -62 34 -52 -22 7 -22 6 4 -11 15 -10 26 -22
24 -28 -2 -5 10 -16 27 -24 26 -11 28 -14 14 -20 -15 -5 -13 -8 12 -19 33 -15
40 -32 13 -33 -15 0 -15 -2 2 -9 11 -5 29 -11 39 -14 18 -6 18 -7 1 -20 -10
-7 -29 -11 -43 -8 -18 4 -23 1 -18 -7 5 -7 18 -11 29 -10 29 3 87 -10 87 -20
0 -5 -15 -13 -32 -18 l-33 -10 48 -8 c66 -10 58 -35 -12 -35 -16 0 -28 -5 -28
-13 0 -11 7 -11 36 -1 20 7 46 13 59 14 19 0 21 -3 11 -19 -7 -10 -24 -23 -38
-29 -21 -9 -17 -10 27 -5 63 7 71 -11 12 -28 -22 -7 -38 -14 -36 -16 2 -3 23
1 45 8 45 13 61 8 41 -16 -11 -13 -9 -15 14 -15 32 0 33 -6 4 -32 -18 -15 -19
-18 -5 -14 22 7 22 -12 0 -36 -16 -18 -16 -18 0 -12 9 3 17 10 17 15 0 4 9 13
21 20 27 14 50 -4 31 -23 -19 -19 -14 -28 13 -28 25 0 33 -14 15 -25 -6 -4 -8
-11 -5 -16 3 -5 0 -6 -8 -3 -8 3 -20 -2 -27 -11 -7 -8 -20 -15 -29 -15 -9 0
-27 -9 -41 -20 -24 -19 -24 -20 -3 -17 11 2 27 8 35 14 15 13 96 43 116 43 6
0 12 -7 12 -15 0 -8 -5 -15 -11 -15 -6 0 -24 -10 -39 -22 -15 -13 -36 -29 -46
-35 -11 -7 -2 -5 19 4 20 9 37 20 37 24 0 11 59 29 65 19 3 -4 2 -16 -2 -26
-5 -13 -3 -16 9 -12 17 7 74 -55 103 -112 35 -70 36 -70 169 -70 103 0 126 3
154 20 19 12 32 28 32 40 0 26 -38 95 -87 159 -21 27 -46 59 -55 72 -32 44
-167 224 -189 251 -31 38 -38 51 -33 59 2 4 -5 10 -16 14 -24 8 -25 18 -5 35
23 19 18 27 -15 22 -50 -7 -32 26 35 62 l30 16 -30 -6 c-16 -4 -42 -14 -57
-22 -24 -14 -28 -14 -42 1 -22 21 -20 37 3 37 11 0 23 5 26 10 4 7 -8 8 -34 4
-33 -5 -41 -4 -41 9 0 8 -7 18 -16 21 -9 4 -14 13 -10 21 3 8 1 14 -3 12 -5
-1 -19 9 -31 23 -20 23 -20 25 -4 29 13 2 9 7 -17 19 l-34 15 47 8 c26 4 55
14 63 23 14 14 68 33 105 38 8 1 -25 5 -75 9 -93 7 -189 27 -215 44 -18 12
-80 89 -80 100 0 5 9 0 20 -10 11 -10 23 -16 26 -13 3 3 -10 15 -28 26 -37 23
-103 96 -104 115 0 7 -22 34 -47 61 -26 27 -45 51 -42 54 2 3 10 1 17 -5 7 -5
24 -14 38 -20 22 -9 26 -8 26 8 0 11 -5 19 -11 19 -5 0 -21 10 -35 21 l-25 21
45 -7 45 -6 -23 21 c-41 38 -22 53 32 25 37 -19 45 -13 11 9 -14 9 -25 23 -25
32 0 24 39 9 138 -54 12 -8 22 -12 22 -9 0 3 -23 19 -51 37 -64 39 -93 66 -81
74 13 8 72 -13 97 -34 33 -28 40 -29 41 -4 1 17 -3 24 -16 24 -20 0 -77 27
-86 41 -11 19 3 20 67 5 35 -8 65 -13 67 -11 2 2 -20 16 -48 30 -68 34 -55 54
19 29 28 -9 55 -14 58 -10 4 3 0 6 -8 6 -8 1 -30 14 -49 31 -34 29 -34 30 -12
35 12 4 22 14 22 23 0 9 31 52 68 96 185 219 489 585 518 625 47 65 52 93 21
124 -39 39 -171 47 -275 14 -29 -9 -105 -90 -209 -224 -13 -16 -55 -70 -95
-121 -40 -50 -102 -128 -137 -172 -37 -46 -72 -81 -82 -81 -12 0 -16 -6 -12
-20 7 -25 -18 -36 -55 -22 -15 6 -39 13 -54 16 -50 11 -30 26 35 26 l62 1 -43
20 c-60 28 -45 43 37 35 l66 -6 -47 21 c-27 12 -48 24 -48 28 0 3 31 42 68 86
37 45 74 95 81 113 16 39 8 76 -19 88 -19 9 -19 10 5 41 16 20 44 38 75 48 64
21 64 36 0 28 -27 -3 -50 -3 -50 0 0 3 16 29 35 58 19 29 31 57 28 62 -3 6
-12 2 -20 -9 -48 -67 -70 -70 -28 -5 14 23 23 46 20 51 -7 12 -50 -36 -59 -67
-4 -13 -14 -23 -21 -23 -16 0 -11 18 17 65 21 35 22 45 6 45 -14 0 -58 -59
-58 -76 0 -18 -58 -67 -73 -61 -8 3 -18 18 -22 34 -8 32 -24 55 -35 48 -7 -5
13 -116 30 -160 20 -53 15 -61 -51 -73 -34 -6 -67 -9 -72 -5 -6 3 -7 1 -4 -4
16 -26 -25 -36 -131 -34 -160 3 -301 11 -358 21 -28 5 -62 6 -75 2 -32 -8 173
-32 317 -36 61 -2 122 -7 135 -12 13 -4 32 -7 42 -5 22 3 21 -8 -2 -38 -18
-24 -19 -24 -107 -13 -49 7 -102 11 -119 10 -16 -1 -64 4 -105 11 -101 17
-260 24 -240 11 13 -9 11 -14 -13 -35 -25 -22 -37 -25 -103 -25 -56 0 -74 3
-74 14 0 16 50 30 123 34 58 4 13 22 -54 22 -54 0 -69 3 -69 15 0 23 34 35 97
35 86 0 46 17 -45 19 -43 1 -99 8 -127 16 l-50 13 58 0 c45 -1 61 3 72 18 12
17 25 19 122 16 59 -1 144 -7 188 -12 44 -5 127 -12 185 -15 58 -4 112 -8 120
-10 28 -7 200 -17 200 -11 0 6 -72 27 -110 31 -87 10 -188 18 -270 21 -52 2
-113 6 -135 9 -35 4 -126 11 -235 19 -54 4 -130 27 -130 40 0 7 6 21 14 31 11
15 17 17 28 8 28 -24 199 -40 271 -27 50 10 17 24 -56 25 -88 0 -214 12 -222
21 -4 3 -2 15 4 27 18 32 -7 37 -35 7 -14 -14 -29 -26 -35 -26 -6 0 -7 -4 -4
-10 3 -5 0 -10 -8 -10 -18 0 -58 91 -46 106 6 7 4 16 -6 24 -8 7 -15 18 -15
25 0 7 -7 18 -15 25 -13 11 -15 8 -15 -14 0 -33 -13 -33 -28 0 -12 24 -32 25
-32 1 0 -18 -67 -14 -112 7 -47 20 -45 20 -59 -5z m244 -76 c3 -16 3 -30 1
-33 -7 -7 -24 23 -24 43 0 27 17 20 23 -10z m-55 -8 c-3 -3 -9 2 -12 12 -6 14
-5 15 5 6 7 -7 10 -15 7 -18z m-40 -31 c13 -4 22 -14 22 -26 0 -15 -4 -18 -22
-12 -28 8 -48 23 -48 35 0 10 16 11 48 3z m267 -160 c0 -12 -7 -19 -17 -18
-72 3 -79 5 -76 17 13 38 93 39 93 1z m415 -174 c19 -12 13 -17 -30 -25 -28
-5 -41 -2 -60 14 l-25 20 50 1 c28 0 57 -4 65 -10z m250 -31 c35 -5 67 -12 69
-15 12 -12 -30 -35 -69 -39 -54 -6 -65 -15 -17 -15 26 0 37 -4 37 -15 0 -22
-33 -35 -74 -28 -23 4 -36 2 -36 -6 0 -6 14 -11 30 -11 19 0 30 -5 30 -14 0
-25 -21 -30 -127 -33 -87 -3 -103 -1 -103 12 0 8 0 18 0 23 0 4 21 10 47 13
l48 6 -51 1 c-42 2 -53 6 -57 21 -6 25 32 34 136 34 42 -1 77 3 77 8 0 10
-149 5 -192 -6 -23 -6 -29 -3 -39 19 -7 14 -9 29 -5 33 20 20 203 27 296 12z
m-530 -46 c3 -2 -4 -12 -16 -22 -15 -12 -34 -16 -63 -13 -45 5 -53 21 -18 39
20 10 85 8 97 -4z m-40 -83 c0 -21 -4 -24 -52 -34 -25 -6 -28 -3 -28 18 0 24
6 27 68 35 6 0 12 -8 12 -19z m-10 -62 c0 -19 -31 -48 -52 -48 -12 0 -18 7
-18 23 0 13 3 27 7 30 11 12 63 8 63 -5z m500 -11 c0 -28 -25 -37 -102 -37
-70 0 -78 2 -78 19 0 26 12 29 103 30 57 1 77 -2 77 -12z m-512 -69 c-2 -17
-11 -24 -30 -26 -35 -4 -39 24 -5 38 34 14 39 12 35 -12z m462 2 c-6 -11 -15
-20 -19 -20 -5 0 -11 -9 -14 -20 -6 -22 -44 -40 -88 -40 -45 0 -35 29 16 49
l30 11 -28 -4 c-32 -4 -44 9 -27 29 9 10 33 15 77 15 61 0 63 -1 53 -20z m423
-37 c37 -5 47 -10 47 -24 0 -15 -8 -19 -37 -20 -21 -1 -30 -3 -20 -6 23 -6 22
-34 -2 -40 -13 -3 -17 -11 -13 -25 4 -17 1 -19 -31 -17 -20 2 -39 0 -43 -4 -4
-4 2 -7 14 -7 28 0 30 -30 3 -37 -12 -3 -17 -11 -14 -19 3 -8 -3 -17 -15 -21
-12 -5 -21 -12 -22 -18 -2 -23 -130 12 -130 35 0 13 8 13 65 -5 27 -8 51 -13
52 -11 5 5 -62 36 -76 36 -6 0 -11 5 -11 11 0 7 16 8 46 3 53 -8 55 10 3 21
-42 9 -27 20 24 17 l42 -2 -30 15 -30 15 34 -5 34 -5 -21 19 c-29 24 -28 31 4
31 23 0 25 2 14 15 -19 22 -8 27 39 16 49 -11 52 -7 11 14 -31 16 -32 42 -1
30 9 -4 37 -9 64 -12z m-883 -28 c0 -14 -3 -25 -7 -25 -5 0 -18 -3 -30 -6 -19
-5 -23 -2 -23 14 0 21 23 41 48 42 6 0 12 -11 12 -25z m380 -36 c0 -5 -14 -15
-31 -21 -23 -8 -27 -13 -17 -19 11 -7 10 -11 -2 -19 -8 -5 -20 -10 -26 -10 -6
0 -10 -10 -10 -22 1 -17 -5 -24 -21 -26 -17 -3 -21 0 -17 12 3 9 13 16 21 16
8 0 12 4 9 9 -3 4 2 27 11 50 14 37 20 41 50 41 18 0 33 -5 33 -11z m-372 -27
c3 -19 -1 -22 -32 -22 -29 0 -36 4 -36 19 0 36 63 39 68 3z m16 -48 c10 -25
-2 -34 -45 -34 -46 0 -49 9 -11 32 34 22 48 22 56 2z m33 -61 c6 -16 -23 -29
-75 -36 -36 -4 -43 -3 -40 11 2 9 21 22 43 29 46 14 67 13 72 -4z m203 -8 c0
-17 -50 -45 -80 -45 -11 0 -20 -4 -20 -9 0 -4 11 -6 25 -3 14 2 25 0 25 -5 0
-21 -38 -41 -100 -52 -36 -7 -74 -17 -85 -21 -11 -5 -35 -13 -52 -19 -28 -8
-33 -8 -33 7 0 9 12 26 28 39 l27 22 -27 1 c-17 0 -28 6 -28 13 0 25 54 40
146 43 69 1 101 7 130 23 47 25 44 24 44 6z m-100 -126 c0 -11 -51 -49 -66
-49 -7 0 -28 -9 -46 -20 -39 -24 -35 -25 21 -5 48 17 52 9 13 -28 -35 -33
-142 -73 -142 -52 0 7 12 23 28 35 l27 21 -27 -6 c-62 -13 -12 54 56 74 17 5
48 16 69 24 42 15 67 18 67 6z m-155 -179 c-10 -11 -14 -20 -9 -20 11 -1 -35
-40 -46 -40 -19 0 -3 52 20 64 39 22 57 20 35 -4z m1320 -422 c17 -16 -14 -25
-68 -20 -51 4 -77 16 -77 34 0 11 131 -1 145 -14z m-955 -303 c82 -108 78
-102 55 -85 -41 30 -90 86 -107 124 -33 69 -27 65 52 -39z"/>
<path d="M1238 2353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1646 2265 c10 -8 23 -15 28 -15 6 0 1 7 -10 15 -10 8 -23 15 -28 15
-6 0 -1 -7 10 -15z"/>
<path d="M940 2069 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M883 2063 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M670 3074 c0 -14 38 -34 66 -34 20 0 43 -4 53 -9 10 -6 23 -6 32 0 8
5 9 9 2 9 -6 0 -19 8 -28 17 -11 11 -36 17 -71 18 -30 1 -54 1 -54 -1z"/>
<path d="M854 3029 c-9 -16 18 -19 155 -16 94 1 141 6 141 13 0 13 -288 15
-296 3z"/>
<path d="M1252 3019 c5 -15 68 -22 68 -8 0 5 -9 9 -19 9 -11 0 -27 3 -37 7
-12 4 -15 2 -12 -8z"/>
<path d="M1070 2930 c0 -6 11 -9 25 -7 37 5 42 17 7 17 -18 0 -32 -5 -32 -10z"/>
<path d="M1151 2926 c2 -2 22 -6 44 -9 28 -5 34 -4 20 3 -19 8 -73 14 -64 6z"/>
<path d="M1258 2913 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M1346 2912 c-8 -14 18 -22 74 -22 27 0 52 -4 55 -10 8 -13 55 -13 55
-1 0 18 -56 39 -75 29 -16 -10 -51 -7 -86 7 -10 3 -20 2 -23 -3z"/>
<path d="M165 2881 c-12 -20 66 -24 84 -5 3 4 -6 4 -22 2 -15 -3 -30 -1 -33 3
-7 12 -21 11 -29 0z"/>
<path d="M150 2799 c0 -7 35 -19 58 -19 7 0 11 4 8 9 -7 11 -66 20 -66 10z"/>
<path d="M248 2793 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M286 2792 c-8 -14 29 -22 108 -22 43 0 66 4 60 9 -13 14 -160 25
-168 13z"/>
<path d="M940 2770 c0 -5 19 -10 43 -9 32 0 37 2 22 9 -28 12 -65 12 -65 0z"/>
<path d="M115 2720 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M229 2399 c-8 -5 -42 -9 -75 -9 -47 0 -63 -4 -68 -16 -9 -25 -8 -27
17 -19 30 9 141 24 255 34 51 4 95 11 99 14 9 10 -212 6 -228 -4z"/>
<path d="M352 2339 c-13 -5 -56 -10 -95 -12 -75 -3 -143 -22 -151 -42 -3 -8
10 -9 43 -4 117 20 230 47 246 61 12 11 -14 9 -43 -3z"/>
<path d="M51 2271 c-9 -6 -10 -11 -2 -15 13 -8 31 1 31 15 0 11 -11 11 -29 0z"/>
<path d="M305 2182 c-22 -10 -67 -26 -100 -36 -33 -10 -71 -21 -84 -25 -30 -9
-39 -27 -14 -28 21 -1 103 25 118 36 5 5 33 16 61 25 56 18 100 45 74 45 -8 1
-33 -7 -55 -17z"/>
<path d="M359 2002 c-13 -10 -53 -35 -89 -55 -36 -19 -81 -50 -100 -67 -31
-28 -32 -30 -5 -12 17 11 57 33 90 51 33 17 78 46 100 66 42 36 45 48 4 17z"/>
<path d="M60 1910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M348 1871 c-36 -27 -79 -56 -95 -64 -30 -16 -113 -96 -113 -109 0
-11 63 28 90 57 13 14 29 25 36 25 7 0 30 20 52 45 22 25 44 45 49 45 5 0 20
11 33 25 34 36 24 31 -52 -24z"/>
<path d="M256 1865 c-11 -8 -15 -15 -9 -15 6 0 16 7 23 15 16 19 11 19 -14 0z"/>
<path d="M1300 1850 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M180 1820 c-46 -39 -34 -39 15 0 21 17 34 30 29 30 -5 0 -25 -14 -44
-30z"/>
<path d="M1920 1846 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M369 1823 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M425 1812 c-5 -4 -16 -15 -24 -25 -12 -15 -12 -17 0 -12 8 3 20 14
27 25 13 22 13 24 -3 12z"/>
<path d="M1970 1816 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M2100 1796 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M306 1759 c-8 -12 -29 -30 -46 -41 -18 -10 -30 -21 -27 -24 7 -7 89
53 100 72 13 22 -10 16 -27 -7z"/>
<path d="M2060 1763 c14 -9 27 -20 30 -23 8 -11 51 -32 56 -27 2 2 -19 18 -47
35 -53 34 -81 44 -39 15z"/>
<path d="M1315 1760 c11 -5 27 -9 35 -9 9 0 8 4 -5 9 -11 5 -27 9 -35 9 -9 0
-8 -4 5 -9z"/>
<path d="M1989 1751 c14 -11 38 -26 55 -36 l31 -16 -34 29 c-18 17 -43 33 -55
36 -20 5 -19 4 3 -13z"/>
<path d="M90 1749 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M370 1754 c0 -10 -76 -91 -110 -116 -12 -9 -21 -21 -19 -27 4 -12 99
70 99 84 0 5 10 19 22 32 12 13 18 26 15 30 -4 3 -7 2 -7 -3z"/>
<path d="M1284 1711 c4 -6 25 -11 46 -11 21 0 42 -4 45 -10 3 -5 11 -10 18
-10 6 0 3 6 -8 14 -26 18 -110 32 -101 17z"/>
<path d="M2167 1699 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M1994 1691 c7 -11 46 -35 46 -28 0 3 -12 12 -26 22 -15 9 -23 12 -20
6z"/>
<path d="M1847 1679 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2065 1648 c11 -7 25 -17 32 -23 18 -19 122 -48 141 -40 12 5 0 11
-38 19 -31 6 -73 22 -93 34 -21 12 -44 22 -50 22 -7 0 -3 -6 8 -12z"/>
<path d="M2150 1656 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z"/>
<path d="M110 1625 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M286 1567 c-38 -39 -64 -73 -58 -75 14 -4 119 104 125 130 3 10 4 18
4 17 -1 0 -33 -33 -71 -72z"/>
<path d="M2010 1632 c0 -8 80 -55 85 -50 2 2 -17 15 -41 30 -24 15 -44 24 -44
20z"/>
<path d="M363 1557 c-38 -50 -50 -77 -34 -77 6 0 11 4 11 9 0 4 11 19 23 32
19 19 46 80 35 79 -2 0 -17 -20 -35 -43z"/>
<path d="M1280 1587 c0 -4 21 -16 48 -29 26 -12 50 -24 55 -26 4 -2 7 2 7 10
0 14 -35 32 -82 44 -16 3 -28 4 -28 1z"/>
<path d="M145 1566 c-5 -11 -3 -21 6 -26 15 -10 36 -1 22 9 -6 3 -12 13 -15
21 -5 12 -7 12 -13 -4z"/>
<path d="M2130 1550 c0 -4 10 -10 23 -14 29 -8 72 -8 64 0 -10 10 -87 22 -87
14z"/>
<path d="M1515 1520 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M379 1468 c-25 -35 -51 -76 -58 -93 -7 -16 -20 -41 -29 -55 -9 -14
-17 -43 -17 -65 -1 -22 -4 -56 -8 -75 -4 -23 0 -16 13 20 12 32 18 66 15 81
-4 18 -1 28 8 32 15 6 57 80 57 101 0 7 7 19 16 27 14 12 59 89 51 89 -2 0
-23 -28 -48 -62z"/>
<path d="M2006 1517 c5 -14 92 -43 101 -33 4 3 1 6 -6 6 -6 0 -31 9 -53 20
-49 24 -49 24 -42 7z"/>
<path d="M2120 1480 c0 -6 33 -10 86 -10 47 0 83 4 80 9 -8 12 -166 14 -166 1z"/>
<path d="M170 1466 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M264 1425 c-9 -37 2 -41 14 -6 7 18 7 31 2 31 -5 0 -12 -11 -16 -25z"/>
<path d="M416 1392 c-10 -20 -24 -50 -32 -66 -13 -24 -13 -32 -2 -43 11 -11
16 -5 26 35 7 26 17 60 22 76 14 42 7 41 -14 -2z"/>
<path d="M189 1373 c-13 -15 -12 -15 8 -5 18 10 24 9 31 -4 5 -10 14 -14 21
-9 9 5 4 12 -14 21 -33 17 -30 17 -46 -3z"/>
<path d="M2240 1370 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M1500 1363 c0 -19 56 -50 99 -56 29 -4 40 -2 30 4 -7 5 -22 9 -31 9
-10 0 -18 4 -18 9 0 5 -15 15 -32 22 -18 7 -36 14 -40 17 -5 2 -8 0 -8 -5z"/>
<path d="M436 1285 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2 2
-6 -3 -10 -12z"/>
<path d="M1509 1270 c31 -29 137 -90 156 -90 4 0 -4 9 -18 20 -14 11 -29 20
-33 20 -4 0 -15 6 -23 14 -9 7 -38 26 -66 41 l-50 28 34 -33z"/>
<path d="M2250 1250 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M1010 1210 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"/>
<path d="M1700 1226 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2278 1213 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1602 1133 c-10 -10 -10 -13 2 -13 8 0 21 -3 29 -6 8 -3 17 1 20 9 7
20 -35 28 -51 10z"/>
<path d="M1737 1140 c10 -10 90 -24 81 -15 -4 4 -26 11 -48 15 -23 4 -38 4
-33 0z"/>
<path d="M923 1095 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M1755 1074 c-13 -10 -15 -14 -4 -14 20 0 43 16 32 23 -4 3 -17 -1
-28 -9z"/>
<path d="M400 1055 c-6 -8 -10 -21 -8 -31 2 -11 7 -6 15 15 13 34 11 38 -7 16z"/>
<path d="M885 1015 c3 -22 5 -54 5 -71 0 -21 5 -31 15 -31 11 0 12 4 5 11 -5
5 -10 26 -11 45 -1 20 -5 47 -10 61 -7 20 -8 17 -4 -15z"/>
<path d="M1066 1028 c10 -14 21 -24 23 -22 3 3 -6 15 -18 27 l-24 22 19 -27z"/>
<path d="M1110 1041 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M1180 1013 c0 -5 13 -17 28 -27 l27 -19 -24 26 c-26 28 -31 32 -31
20z"/>
<path d="M1840 1010 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M941 990 c1 -36 12 -60 24 -52 7 4 4 19 -7 42 -16 32 -18 33 -17 10z"/>
<path d="M392 970 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M1040 984 c0 -10 72 -88 84 -93 6 -2 -6 14 -26 36 -21 21 -38 44 -38
51 0 7 -4 12 -10 12 -5 0 -10 -3 -10 -6z"/>
<path d="M1267 940 c18 -19 34 -32 36 -29 2 2 -12 17 -32 33 l-36 30 32 -34z"/>
<path d="M1150 922 c0 -9 25 -33 47 -46 8 -4 11 -2 7 6 -6 17 -54 52 -54 40z"/>
<path d="M1788 923 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M926 887 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M1820 884 l-35 -14 45 6 c25 4 52 10 60 15 26 14 -31 9 -70 -7z"/>
<path d="M867 829 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M1978 838 c-16 -5 -28 -16 -28 -24 0 -16 16 -10 55 20 23 17 13 19
-27 4z"/>
<path d="M1189 827 c10 -13 50 -34 116 -63 22 -10 43 -19 48 -21 4 -2 7 -2 7
1 0 3 -41 25 -91 50 -50 25 -86 39 -80 33z"/>
<path d="M920 806 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z"/>
<path d="M1953 754 c-18 -16 -21 -22 -8 -15 32 15 61 40 48 41 -7 0 -25 -12
-40 -26z"/>
<path d="M1176 762 c-7 -11 23 -25 102 -48 35 -9 65 -21 68 -26 3 -4 10 -8 17
-8 21 0 -27 27 -83 45 -30 11 -62 25 -71 32 -18 15 -26 16 -33 5z"/>
<path d="M866 725 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2 0
-1 -7 2 -15z"/>
<path d="M2069 719 c-23 -19 -23 -20 -2 -10 12 6 25 16 28 21 10 16 -1 12 -26
-11z"/>
<path d="M952 704 c9 -9 21 -15 27 -13 6 2 -2 10 -17 17 -25 11 -25 11 -10 -4z"/>
<path d="M1010 690 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M1170 659 c0 -5 11 -9 25 -9 14 0 25 2 25 4 0 2 -11 6 -25 8 -14 3
-25 1 -25 -3z"/>
<path d="M1408 663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M973 653 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M1458 653 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1050 636 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z"/>
<path d="M2190 603 c-8 -3 -17 -9 -20 -13 -7 -10 -90 -59 -112 -67 -10 -3 -18
-9 -18 -14 0 -12 134 58 155 81 19 21 19 22 -5 13z"/>
<path d="M851 587 c-6 -6 -11 -14 -11 -18 0 -9 39 1 45 11 8 13 -22 19 -34 7z"/>
<path d="M1255 590 c17 -4 46 -8 65 -8 l35 0 -40 9 c-22 4 -51 8 -65 7 -23 0
-22 -1 5 -8z"/>
<path d="M907 545 c-15 -11 -3 -12 72 -11 98 1 101 10 6 19 -41 3 -67 1 -78
-8z"/>
<path d="M825 540 c-3 -5 -14 -10 -23 -10 -9 0 -28 -19 -42 -41 -14 -23 -33
-50 -42 -62 -21 -23 -22 -33 -7 -42 6 -4 9 -1 8 6 -6 25 50 98 85 113 37 15
52 31 37 40 -5 4 -12 2 -16 -4z"/>
<path d="M1347 534 c9 -4 10 -11 3 -25 -10 -17 -8 -18 17 -8 16 6 48 14 73 19
l45 8 -50 1 c-27 1 -61 4 -75 6 -14 3 -19 3 -13 -1z"/>
<path d="M1160 498 c0 -10 -5 -18 -11 -18 -5 0 -7 -5 -4 -11 5 -7 11 -5 18 5
7 8 28 19 47 24 l35 9 -42 4 c-36 4 -43 2 -43 -13z"/>
<path d="M941 496 c8 -8 186 -20 179 -13 -3 3 -46 9 -95 12 -49 4 -87 4 -84 1z"/>
<path d="M1288 493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2105 490 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M868 483 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1418 463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1938 454 c-16 -8 -28 -16 -28 -18 0 -9 40 7 51 20 14 17 11 17 -23
-2z"/>
<path d="M2029 449 c-19 -16 -20 -18 -4 -10 34 17 51 31 39 31 -7 0 -22 -9
-35 -21z"/>
<path d="M793 448 c-25 -32 -24 -38 4 -38 20 0 24 3 14 9 -8 5 -11 16 -8 25 8
19 3 21 -10 4z"/>
<path d="M1355 450 c-18 -8 -17 -9 7 -9 15 -1 30 4 33 9 7 12 -12 12 -40 0z"/>
<path d="M885 441 c-11 -5 16 -6 60 -4 104 6 119 11 30 12 -38 0 -79 -4 -90
-8z"/>
<path d="M1140 440 c-10 -7 14 -8 75 -4 49 3 92 8 94 10 9 9 -155 3 -169 -6z"/>
<path d="M1730 409 c-14 -6 -36 -8 -49 -6 -13 3 -38 -1 -55 -9 -26 -11 -27
-13 -9 -14 12 0 24 4 27 9 4 6 20 5 42 0 28 -8 41 -7 60 6 39 25 30 32 -16 14z"/>
<path d="M908 399 c-25 -4 -55 -12 -65 -18 -16 -10 -15 -11 7 -7 14 3 50 8 79
11 30 3 56 7 58 9 9 9 -36 11 -79 5z"/>
<path d="M1303 403 c-30 -3 -43 -9 -43 -19 0 -10 6 -13 19 -9 10 3 38 12 62
19 45 14 38 16 -38 9z"/>
<path d="M1824 400 c-24 -9 -41 -30 -26 -30 6 0 20 9 33 20 26 21 25 23 -7 10z"/>
<path d="M2019 383 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M1075 380 c-4 -7 3 -8 22 -4 38 9 42 14 10 14 -14 0 -29 -5 -32 -10z"/>
<path d="M1158 383 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M790 376 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M1510 352 c-44 -16 -75 -33 -75 -41 0 -18 -62 -51 -97 -51 -14 0 -33
-7 -41 -15 -8 -7 -27 -17 -42 -21 -40 -10 -100 -47 -101 -61 -2 -30 -5 -34
-26 -43 -21 -8 -20 -9 10 -9 25 -1 35 4 44 24 6 14 17 25 25 25 7 0 13 5 13
10 0 6 5 10 12 10 6 0 19 6 27 14 9 7 43 21 76 31 82 23 134 53 128 74 -5 18
16 32 79 51 21 6 40 16 43 21 4 5 5 9 3 8 -2 0 -37 -13 -78 -27z"/>
<path d="M1360 360 c-24 -8 -24 -8 5 -8 17 0 41 4 55 8 24 8 24 8 -5 8 -16 0
-41 -4 -55 -8z"/>
<path d="M1853 360 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23 -1z"/>
<path d="M860 340 c-20 -6 -21 -8 -5 -8 11 0 34 4 50 8 27 7 27 8 5 8 -14 0
-36 -4 -50 -8z"/>
<path d="M1075 334 l-50 -12 40 0 c22 0 54 3 70 7 25 6 27 8 10 12 -11 3 -42
0 -70 -7z"/>
<path d="M1188 343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1250 322 c-26 -10 -38 -18 -28 -20 16 -3 78 23 78 33 0 3 -1 4 -2 4
-2 -1 -23 -9 -48 -17z"/>
<path d="M808 323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1760 320 c-18 -12 -2 -12 25 0 13 6 15 9 5 9 -8 0 -22 -4 -30 -9z"/>
<path d="M1845 304 c-27 -13 -55 -24 -62 -24 -6 0 -14 -4 -18 -9 -9 -15 60 7
107 34 55 31 38 31 -27 -1z"/>
<path d="M925 311 c-23 -3 -35 -9 -28 -14 6 -4 35 -1 65 7 54 15 36 18 -37 7z"/>
<path d="M1348 303 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1120 291 c-33 -4 -49 -9 -39 -13 19 -7 70 -1 103 12 23 10 18 10
-64 1z"/>
<path d="M1245 274 c-33 -14 -79 -30 -103 -36 -40 -10 -67 -28 -41 -28 14 0
219 80 219 86 0 8 -15 3 -75 -22z"/>
<path d="M1550 275 c-14 -8 -30 -14 -37 -15 -19 0 -87 -44 -75 -48 6 -2 21 3
34 12 13 8 52 17 87 20 36 3 69 10 75 16 7 7 1 8 -21 4 -23 -5 -32 -2 -35 9
-3 14 -6 14 -28 2z"/>
<path d="M988 273 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M880 250 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5 0
-17 -5 -25 -10z"/>
<path d="M1725 250 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M1844 245 c-18 -13 -18 -14 4 -8 12 3 25 9 28 14 8 14 -10 11 -32 -6z"/>
<path d="M1669 228 c-28 -7 -36 -14 -32 -27 5 -19 3 -20 -102 -56 -53 -19 -98
-52 -89 -67 3 -4 12 -1 21 8 15 15 17 14 27 -4 16 -28 36 -16 30 17 -6 27 -4
29 39 35 49 8 97 38 97 62 0 8 11 19 25 24 14 5 25 12 25 15 0 3 -1 5 -2 4 -2
-1 -19 -5 -39 -11z"/>
<path d="M1800 221 c0 -5 -14 -14 -30 -21 -44 -18 -44 -26 1 -22 22 3 38 7 35
9 -3 3 -1 14 5 24 6 13 6 19 -1 19 -5 0 -10 -4 -10 -9z"/>
<path d="M1991 209 c-13 -10 -19 -19 -14 -19 13 0 49 27 43 33 -3 3 -16 -3
-29 -14z"/>
<path d="M1035 200 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M1344 177 c-89 -40 -130 -70 -119 -87 6 -10 10 -9 17 4 5 9 15 16 22
16 7 0 21 9 31 20 10 11 35 25 56 32 21 6 46 19 55 30 22 23 22 23 -62 -15z"/>
<path d="M1524 195 c-18 -14 -17 -15 11 -9 37 8 57 24 29 24 -11 0 -29 -7 -40
-15z"/>
<path d="M1445 159 c-22 -10 -46 -19 -53 -19 -16 0 -109 -70 -94 -70 7 0 38
14 69 32 32 17 75 40 96 50 20 10 37 21 37 23 0 8 -13 4 -55 -16z"/>
<path d="M1663 129 c-18 -12 -33 -23 -33 -25 0 -7 58 25 65 36 9 14 5 13 -32
-11z"/>
<path d="M1967 144 c-3 -3 -31 -6 -62 -5 -42 0 -64 -5 -84 -20 -14 -11 -49
-25 -76 -32 -59 -14 -69 -40 -17 -45 17 -2 32 1 32 7 0 5 23 9 51 9 44 -1 53
2 73 27 15 18 28 26 39 22 9 -3 26 4 42 19 14 13 22 24 17 24 -5 0 -11 -3 -15
-6z"/>
<path d="M1570 100 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
</g>
</svg>
  </a>
);
