import { FETCH_BUYERS, CLEAR_BUYER, ADD_BUYER } from './actionTypes';
import axios from 'axios';

import { buyersAPI } from '../util';



export const fetchBuyers = (filters, sortBy, callback) => dispatch => {
  return axios
    .get(`${buyersAPI}?action=read&table=Customers`)
    .then(res => {
      
      let buyers = res.data.data;
     
      if (!!callback) {
        callback();
      }

      return dispatch({
        type: FETCH_BUYERS,
        payload: buyers
      });
    })
    .catch(err => {
      console.log('Could not fetch buyers. Try again later.');
    });
};
export const insertBuyers = (buyer, callback) => dispatch => {
  return axios
    .get(`${buyersAPI}?action=insert&table=Customers&data={"name":"${buyer}"}`)
    .then(res => {
      
     // let result = res.data.data;
      
      if (!!callback) {
        callback();
      }

      console.log(`registro exitoso de ${buyer} `)
      // return dispatch({
      //   type: INSERT_BUYERS,
      //   payload: buyer
      // });
    })
    .catch(err => {
      console.log(`Could not insert the buyer. Try again later.`);
    });
};

export const addBuyer = addBuyer => ({
  type: ADD_BUYER,
  payload: addBuyer
  
});
export const clearBuyer = () => ({
  type: CLEAR_BUYER,

});