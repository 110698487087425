import { FETCH_BUYERS, ADD_BUYER, CLEAR_BUYER } from './actionTypes';

const initialState = {
  buyers: [],
  buyer: {},
  activeBuyer: { id: 0, value: "Seleccione o cree un cliente", label: "Seleccione o cree un cliente" } ,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_BUYERS:
      return {
        ...state,
        buyers: action.payload
      };
      case ADD_BUYER:
      return {
        ...state,
        activeBuyer: Object.assign({}, action.payload)
      };
      case CLEAR_BUYER:
      return {
        ...state,
        activeBuyer: { id: 0, value: "Seleccione o cree un cliente", label: "Seleccione o cree un cliente" } 
      };
    default:
      return state;
  }
}
