import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Thumb from '../../../Thumb';
import { formatPrice } from '../../../../services/util';
import { addProduct } from '../../../../services/cart/actions';

const Product = ({ product, addProduct }) => {
  product.quantity = 1;
  let formattedPrice = formatPrice(product.Price, product.currencyId);

  let productInstallment;


  if (!!product.installments) {
    const installmentPrice = product.Price / product.installments;

    productInstallment = (
      <div className="installment">
        <span>or {product.installments} x</span>
        <b>
          {product.currencyFormat}
          {formatPrice(installmentPrice, product.currencyId)}
          {console.log(product.currencyId)}
        </b>
      </div>
    );
  }

  return (
    
    <div
      className="shelf-item"
      onClick={() => addProduct(product)}
      data-sku={product.Code}
    >

      {product.isFreeShipping && (
        <div className="shelf-stopper">Free shipping</div>
      )}
      <Thumb
        classes="shelf-item__thumb"
        src={`https://vendoka.com/img/bestly/${product.Code}.jpg`}
        alt={product.Name}
      />
      <div className="shelf-item-codeAndQty">
      <div className="shelf-item-code">{product.Code}</div>
      <div className="shelf-item-Qty">{product.Qty}</div>
      </div>
      <p className="shelf-item__title">{product.Name}</p>
      <div className="shelf-item__price">
        <div className="val">
          <small>{product.currencyFormat}</small>
          <b>{formattedPrice.substr(0, formattedPrice.length - 3)}</b>
          <span>{formattedPrice.substr(formattedPrice.length - 3, 3)}</span>
        </div>
        {productInstallment}
      </div>
      <div className="shelf-item__buy-btn">Add to cart</div>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired
};

export default connect(
  null,
  { addProduct }
)(Product);
