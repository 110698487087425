export const formatPrice = (x) => {
      return parseFloat(x).toFixed(2);
};

export const productsAPI =
'https://script.google.com/macros/s/AKfycbz3CB_PNHhKTr3ZvkWKV-u3gs6ReAfg_-nP9uYw4cw7_gj_mD8/exec'
//'http://localhost:8383/api/index.php/products?sortfield=t.ref&sortorder=ASC&limit=100';
//  'https://react-shopping-cart-67954.firebaseio.com/products.json';
// export const productsAPI = "http://localhost:8001/api/products";
export const ordersAPI = 
'https://script.google.com/macros/s/AKfycbwIyxo0AAaDlgUnQZnbY_ich9o_-2zUkxLl1IXLLyFD4HGOeDY/exec'
//'https://script.google.com/macros/s/AKfycbwIyxo0AAaDlgUnQZnbY_ich9o_-2zUkxLl1IXLLyFD4HGOeDY/exec?action=read&table=Customers';
//'http://localhost:8383/api/index.php/products?sortfield=t.ref&sortorder=ASC&limit=100';
export const buyersAPI = 
'https://script.google.com/macros/s/AKfycbwIyxo0AAaDlgUnQZnbY_ich9o_-2zUkxLl1IXLLyFD4HGOeDY/exec';