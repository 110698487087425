import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { fetchBuyers, addBuyer } from '../../services/buyers/actions';


class Buyers extends Component {
  static propTypes = {
    fetchBuyers: PropTypes.func.isRequired,
    addBuyer:PropTypes.func.isRequired
  };

  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.handleFetchBuyers();
  }

  componentWillReceiveProps(nextProps) {

  }

  handleFetchBuyers = (
    buyers = this.props.buyers,
  ) => {
      this.setState({ isLoading: true });
      this.props.fetchBuyers(buyers, () => {
        this.setState({ isLoading: false });
    });
  };

  handleChange = (newValue: any, actionMeta: any) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    this.props.addBuyer(newValue)

  };
  handleInputChange = (inputValue: any, actionMeta: any) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
   
  };

  render() {
    const { buyers } = this.props;
   const items = buyers.buyers.map( (res, i) => { return { id: i, value: res.name, label: res.name } })
    const { isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading}
        <div className="">
            <CreatableSelect
            isClearable
            value={this.props.activeBuyer}
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            options={items}
            formatCreateLabel={(inputText) => `Crear nuevo cliente "${inputText}" ?`}
            placeholder={"Seleccione o cree un cliente"}
          />
              
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  buyers: state.buyers,
  activeBuyer: state.buyers.activeBuyer
});

export default connect(
  mapStateToProps,
  { fetchBuyers, addBuyer }
)(Buyers);
